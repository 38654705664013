import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Header, StyledHeader } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Header" title="Header" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-header"
    }}>{`Basic header`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Header>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-header"
    }}>{`Bordered header`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Header bordered>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "quiet-bordered-header"
    }}>{`Quiet bordered header`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Header bordered volume=\"quiet\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered volume="quiet" mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "transparent-header"
    }}>{`Transparent header`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Header bordered transparent>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered transparent mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "secondary-header"
    }}>{`Secondary header`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Header variant=\"secondary\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header variant="secondary" mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-secondary-header"
    }}>{`Bordered secondary header`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Header bordered variant=\"secondary\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered variant="secondary" mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "quiet-bordered-secondary-header"
    }}>{`Quiet bordered secondary header`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <Header bordered variant=\"secondary\" volume=\"quiet\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered variant="secondary" volume="quiet" mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "bordered-tertiary-header"
    }}>{`Bordered tertiary header`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Header bordered variant=\"tertiary\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered variant="tertiary" mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "quiet-bordered-tertiary-header"
    }}>{`Quiet bordered tertiary header`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Header bordered variant=\"tertiary\" volume=\"quiet\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered variant="tertiary" volume="quiet" mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <h2 {...{
      "id": "pseudo-bordered-header"
    }}>{`Pseudo-bordered header`}</h2>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <Header bordered=\"pseudo\">\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header bordered="pseudo" mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "contrast-header"
    }}>{`Contrast header`}</h3>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <Header contrast>\n    <div>Hello world</div>\n    <div>Hello world</div>\n  </Header>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Header contrast mdxType="Header">
      <div>Hello world</div>
      <div>Hello world</div>
    </Header>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "styled-header"
    }}>{`Styled header`}</h3>
    <Playground __position={12} __code={'<StyledHeader\n  bordered\n  backgroundColor=\"#556270\"\n  borderColor=\"#393a61\"\n  height={50}\n  textColor=\"#fff\"\n>\n  <div>Hello world</div>\n  <div>Hello world</div>\n</StyledHeader>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      ThemeWrapper,
      Header,
      StyledHeader,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StyledHeader bordered backgroundColor="#556270" borderColor="#393a61" height={50} textColor="#fff" mdxType="StyledHeader">
    <div>Hello world</div>
    <div>Hello world</div>
  </StyledHeader>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Header} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      